<template>
  <div class="coursechapters innerpage">
    <Header
      size="medium"
      title="COVID-19 Online Risk Assessment (CORA) Planning Tool"
      name="course-header"
    ></Header>

    <v-container>
      <v-row>
        <v-col>
          <v-breadcrumbs :items="breadcrumbsItems"></v-breadcrumbs>
        </v-col>
      </v-row>
      <v-row>
        <v-snackbar v-model="snackbar" right top>
          {{ alert }}
          <template v-slot:action="{ attrs }">
            <v-btn color="white" text v-bind="attrs" @click="snackbar = false"
              >Close</v-btn
            >
          </template>
        </v-snackbar>

        <v-col md="4">
          <h1>Chapters</h1>
        </v-col>
        <v-col offset-md="4" md="4" align="right">
          <v-btn
            @click="addItem"
            color="cora-chip-color-green"
            dark
            rounded
          >
            + add chapter
          </v-btn>
        </v-col>
      </v-row>
      <v-divider class="mt-8 mb-3"></v-divider>
      <v-row>
        <v-col>
          <v-data-table
            :loading="dataLoading"
            loading-text="Loading... Please wait"
            item-key="id"
            :disable-sort="true"
            :headers="chaptersHeaders"
            :items="chapters"
            :server-items-length="itemsTotal"
            :sort-desc="[false, true]"
            multi-sort
            class="elevation-5 mb-15 align-center"
            :footer-props="{
              itemsPerPageOptions: [10]
            }"
          >
            <template v-slot:item.actions="{ item }">
              <span class="group">
                <v-icon class="pa-2" @click="editItem(item)"
                  >mdi-playlist-edit</v-icon
                >
                <v-icon @click="deleteItem(item)">mdi-delete</v-icon>
              </span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col align="right">
          <v-btn
            @click="backToCourses()"
            class="back-to-blue"
            color="cora-chip-color-blue"
            dark
            rounded
          >
            back to courses
          </v-btn>
        </v-col>
      </v-row>
      <v-dialog v-model="dialog" max-width="1000px">
        <v-card>
          <v-card-title>
            <h3 class="text-h4">{{ formTitle }}</h3>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-form ref="form" v-model="valid" lazy-validation>
                    <v-text-field
                      filled
                      v-model="editedItem.title"
                      label="Title"
                      :rules="[v => !!v || 'title is required']"
                      required
                    ></v-text-field>
                    <v-subheader>Content:</v-subheader>
                    <TiptapVuetify
                      v-model="editedItem.content"
                      :extensions="extensions"
                    >
                    </TiptapVuetify>
                  </v-form>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialog = false">
              Cancel
            </v-btn>
            <v-btn color="primary" text @click="saveItem(editedItem)">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header";
import CoursesService from "../services/courses.service";
//import Tiptap from "@/components/TipTap";
// import the component and the necessary extensions
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History
} from "tiptap-vuetify";

export default {
  components: {
    Header,
    TiptapVuetify
  },
  data: () => ({
    extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3]
          }
        }
      ],
      Bold,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak
    ],
    breadcrumbsItems: [
      {
        text: "Home",
        disabled: false,
        href: "/"
      },
      {
        text: "Courses",
        disabled: false,
        href: "/mycourses"
      },
      {
        text: "Chapters",
        disabled: true,
        href: "/coursechapters"
      }
    ],

    chaptersHeaders: [
      {
        text: "title",
        value: "title",
        width: "80%"
      },
      // {
      //   text: "Assigned Quiz",
      //   value: "quiz"
      // },
      {
        text: "Actions",
        value: "actions"
      }
    ],
    chapters: [],
    quizzes: [],
    editedItem: {
      id: "",
      title: "",
      content: "",
      quiz: ""
    },
    originalItem: {
      id: "",
      title: "",
      content: "",
      quiz: ""
    },
    apiError: "",
    itemsTotal: 5,
    dataLoading: false,
    isLoaded: false,
    dialog: false,
    editedIndex: -1,
    snackbar: false,
    valid: true,
    alert: ""
  }),

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },

    orgId() {
      return this.$store.state.auth.user.user.organizations.id;
    },

    formTitle: {
      get() {
        return this.editedIndex === -1 ? "Create New Chapter" : "Edit Chapter";
      },
      set(newTitle) {
        return newTitle;
      }
    }
  },
  created() {
    if (!this.currentUser) {
      this.$router.push("/login");
    } else {
      this.initialize();
    }
  },

  methods: {
    async initialize() {
      CoursesService.getCourse(this.$route.params.id).then(
        response => {
          (this.isLoaded = true), (this.course = response.data.data);
          this.chapters = this.course.chapters ? this.course.chapters : [];
          //this.quizzes = this.course.quizzes;
          if (this.chapters) {
            this.chapters.forEach(chapter => {
              if (chapter.quiz_id) {
                chapter.quiz = this.quizzes.filter(quiz => {
                  if (quiz.id === chapter.quiz_id) {
                    return quiz;
                  }
                });
              }
            });
          }
        },
        error => {
          this.apiError =
            (error.response && error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    addItem() {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.dialog = true;
      this.editedIndex = -1;
    },
    editItem(item) {
      this.formTitle = "Edit Chapter";
      this.originalItem = Object.assign({}, item);
      this.editedIndex = this.chapters.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    async saveItem(item) {
      const isValid = this.$refs.form.validate();
      if (isValid) {
        if (this.editedIndex > -1) {
          // update item
          const data = {
            id: item.id,
            course_id: this.$route.params.id,
            title: item.title,
            content: item.content
            //quiz_id: item.quiz.id
          };
          const response = await CoursesService.updateChapter({
            data: data
          });
          this.editedItem = response.data;
          this.chapters.splice(this.editedIndex, 1, this.editedItem);
          this.showSnackbar("The Course has been updated successfully");
          this.dialog = false;
        } else {
          // create item

          const data = {
            course_id: this.$route.params.id,
            title: item.title,
            content: item.content
            // quiz_id: item.quiz.id ? item.quiz.id : null
          };
          const response = await CoursesService.createChapter({
            data: data
          });
          this.chapters.push(response.data);

          this.showSnackbar("The Course has been added successfully");
        }
      }
      this.$refs.form.reset();
      this.editedIndex = 0;
      this.dialog = false;
    },
    randomNumber() {
      return Math.floor(Math.random() * (10 - 1 + 1)) + 1;
    },
    async deleteItem(item) {
      const index = this.chapters.indexOf(item);
      confirm("Are you sure you want to delete this chapter?") &&
        this.chapters.splice(index, 1);

      await CoursesService.removeChapter({
        id: item.id
      });

      this.showSnackbar(item.title + " has been deleted");
    },
    showSnackbar(msg) {
      this.alert = msg;
      this.snackbar = true;
    },
    backToCourses() {
      window.location.href = "/mycourses";
    }
  }
};
</script>

<style lang="scss" scoped>
.back-to-blue {
  background-color: #c5e1e9 !important;
  color: #2f91ae;
}
.courses-card {
  padding-bottom: 10px;
  .header-card {
    color: #3691b3;
  }
  .title-card {
    font-family: "Roboto", sans-serif;
    color: rgba(0, 0, 0, 0.6);
  }

  .text-card {
    font-family: "Roboto", sans-serif;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 0.5px;
    line-height: 21px;
  }
}
</style>
